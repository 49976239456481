import axios from 'axios'

axios.interceptors.request.use(config => { 
    config.headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Accept": "application/json"
    }
    config.baseURL = "https://home.lvyac.com";//api地址
    return config
}, error => { 
    return Promise.reject(error)
})

axios.interceptors.response.use(response => { 
    let code = response.status.toString().charAt(0)
    if (code == '2' && response.data.code == 0) {
        return response.data.data
    } else { 
        return Promise.reject(response.data)
    }
    return 
}, error => { 
    const httpCode = error?.response?.status?.toString()
    const code = httpCode?.charAt(0)
    if (httpCode == 401) { 
        localStorage.setItem('token', '')
        return Promise.reject({"code": -1, "message": "未登录"})
    }
    if (code == '4') { 
        return Promise.reject(error?.response?.data)
    }

    if (code == '5') { 
        return Promise.reject({"code": -1, "message": "服务器故障"})
    }
    return Promise.reject(error?.response)
})

export default axios