import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './route';

import axios from './utils/http'
Vue.prototype.$axios = axios;

import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
Vue.directive("highlight", function (el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach(block => {
    hljs.highlightBlock(block);
  });
});
// 增加组定义属性，用于在代码中预处理代码格式
Vue.prototype.$hljs = hljs;


Vue.use(ElementUI);

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});
